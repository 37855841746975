import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import StoryCard from "../components/StoryCard"
import { MultiLangBody, MultiLangField } from "skillstrainer-resource-library"
import messageicon from "../assets/image/messageicon.svg"
import phoneicon from "../assets/image/phoneicon.svg"
import locationicon from "../assets/image/locationicon.svg"
import { webappHost } from "../../config"
function contactus({ pageContext }) {
  let { stories } = require("../data.json")
  stories = stories.data
  return (
    <MultiLangBody _key={pageContext.key}>
      <Layout title="Contact Us">
        <div className="content mx-auto">
          <div className="flex flex-col bg-light lg:flex-row sm:items-center justify-between pt-36 px-6 pb-10 lg:px-0">
            <div
              className="p-5 flex flex-col justify-between  "
              style={{ height: "400px" }}
            >
              <div className="flex flex-col justify-between">
                <p className="text-base red-dark font-semibold">Contact Us</p>
                <p className="text-3xl blue-dark1 font-semibold my-2">
                  How can we help you?
                </p>
                <div className="w-40 h-1 bg-red-dark"></div>
              </div>
              <div className="flex flex-row  items-center  mt-6 p-4 border-solid border  border-gray-300 rounded-lg">
                <img src={messageicon} className="mr-4" />
                <p className="text text-base  text-center lg:text-left ">
                  support@skillstrainer.in
                </p>
              </div>
              <div className="flex flex-row  items-center mt-6 p-4 border-solid border  border-gray-300 rounded-lg">
                <img src={locationicon} className="mr-8" />
                <p className="text text-base   lg:text-left">
                  Unifiers Social Ventures Pvt Ltd
                  <br />
                  B-100, LGF, Sarvodya Enclave, New Delhi 110017, India{" "}
                </p>
              </div>
              <div className="flex flex-row  items-center  mt-6 p-4 border-solid border border-gray-300 rounded-lg">
                <img src={phoneicon} className="mr-4" />
                <p className="text text-base  text-center lg:text-left">
                  +91-9599574754
                </p>
              </div>
            </div>
            <div className="mt-16 ml-10 lg:mt-0 lg:w-3/5 border-solid border-8 border-white rounded-lg">
              {
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.0615942161503!2d77.19900841425704!3d28.53786829511169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce206d75532f3%3A0x72baeffa62fac45f!2sUnifiers%20Social%20Ventures%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1635130910214!5m2!1sen!2sin"
                  style={{
                    border: 0,
                    width: "100%",
                    height: 400,
                    allowfullscreen: "",
                    loading: "lazy",
                  }}
                ></iframe>
              }
            </div>
          </div>
          <div className="flex flex-col xl:flex-row items-center justify-between">
            <div className="flex flex-col items-start xl:items-end justify-between my-20 xl:my-0">
              <div></div>
              {/* dangerouslySetInnerHTML={{ __html: ThisCouldBeYou }} */}
              <p className="blue-dark2 text-4xl font-semibold xl:text-right">
                <MultiLangField name={"Journey_aside1a"}>
                  This Could
                </MultiLangField>{" "}
                <br />
                <MultiLangField name={"Journey_aside1b"}>
                  be you!
                </MultiLangField>
              </p>
              {/* data cmng from strapi in this div */}
              <div>
                <button className="bg-orange text-white font-semibold rounded-lg px-5 py-3 mt-8 text-sm">
                  <a href={webappHost} target="_blank">
                    <MultiLangField name={"Journey_button"}>
                      Join for Free
                    </MultiLangField>
                  </a>
                </button>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center mt-16 lg:mt-0 story-cards">
              {stories.slice(0, 3).map(story => (
                <StoryCard data={story} />
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </MultiLangBody>
  )
}

export default contactus
